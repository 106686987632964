module.exports = {
    header: {
        searchText: "查找NFT",
        loginText: "登录",
        regText: "注册",
        nav: {
            home:"首页",
            explore: "市场",
            stats: "统计",
            company: "关于公司",
            activity: "活动",
            ranking: "排行榜",
            about: "关于我们",
            news: "新闻",
            contact: "关于我们",
            careers: "事业"
        }
    },
    footer: {
        content: "新加坡领先的加密收藏品交易市场。",
        term: "Terms",
        policy: "Privacy Policy",
        copyRight: "Copyright © 2022 MetaViva",
        nav: {
            marketPlace: "MarketPlace",
            account: "My Account",
            company: "Company",
            allNft: "All NFTs",
            artNft: "Art",
            collectiblesNft: "Collectibles",
            sportsNft: "Sports",
            utilityNft: "Utility",
            about: "About Us",
            news: "News",
            contact: "Contact Us",
            careers: "Careers",
            profile: "Profile Settings",
            collections: "My Collections",
            settings: "Seller Settings",
        }
    },
    slider: {
        title: "Discover rare artworks by world class artists",
        subTitle: "The Singapore's first and largest digital marketplace for crypto collectibles and non-fungible tokens (NFTs)."
    },
    page: {
        login: {
            title: "Login In To MetaViva",
            subTitle: "Please login to continue",
            userLabel: "Email/Mobile",
            passLabel: "Password",
            rememberText: "Remember me",
            forgetText: "Forget Password?",
            noAccountText: "Don't have an account?",
            passToggle: "Toggle show/hide password",
            signUpText: "Sign Up",
            buttonText: "Login",
            regSuccessText: "Sign Up Successful",
            resetSuccessText: "Reset Password Successful"
        },
        reg: {
            title: "Sign Up To MetaViva",
            subTitle: "Fill up the below fields to create an account",
            mobileTab: "Mobile Phone",
            emailTab: "Email",
            mobileLabel: "Mobile Phone",
            emailLabel: "Email Address",
            nicknameLabel: "Full Name",
            passwordLabel: "Password",
            passwordToggle: "Toggle show/hide password",
            regButton: "Sign Up Now",
            hasAccountText: "Have an MetaViva account?",
            loginLink: "Login"
        },
        regVerify: {
            title: "Get Verification Code",
            subTitle: "Verification code has been sent to:",
            verifyCodeLabel: "Verification Code",
            verifyCodePlaceholder: "Verification Code",
            submitButton: "Confirm",
            sendButton: "Send",
            sendWaitText: 'Resend Code in {seconds} seconds',
        },
        forget: {
            title: "Reset Password",
            subTitle: "",
            mobileTab: "Mobile Phone",
            emailTab: "Email",
            mobileLabel: "Mobile Phone",
            emailLabel: "Email Address",
            submitButton: "Confirm",
        },
        reset: {
            title: "Reset Your Password",
            subTitle: "",
            passwordLabel: "New Password",
            repeatLabel: "Password Again",
            submitButton: "Reset",
        },
    },
}