const state = () => ({
    isRegSuccess: false,
    isReSetSuccess: false,
    user: {},
})

const getters = {}

const actions = {}

const mutations = {
    setUser(state, payload) {
        state.user = payload
    },
    setRegSuccess(state, isSuccess = true) {
        state.isRegSuccess = isSuccess
    },
    setResetSuccess(state, isSuccess = true) {
        state.isReSetSuccess = isSuccess
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}