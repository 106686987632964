import {createI18n} from 'vue-i18n'
import en from '@/locales/en'
import zh from '@/locales/zh'

const locale=localStorage.getItem("locale")?localStorage.getItem("locale"):"en"
const i18n = createI18n({
    fallbackLocale: 'en',
    globalInjection: true,
    legacy: false,
    locale: locale,
    messages: {
        en: en,
        zh: zh
    }
});
export default i18n