import {createWebHistory, createRouter} from "vue-router";

const Home = () => import( "@/pages/Home");
const Explore = () => import( "@/pages/Explore");
const Detail = () => import( "@/pages/Detail");
const Login = () => import( "@/pages/Login");
const Reg = () => import( "@/pages/Reg");
const RegVerify = () => import( "@/pages/RegVerify");
const ForgetReset = () => import( "@/pages/ForgetReset");
const ForgetVerify = () => import( "@/pages/ForgetVerify");
const Forget = () => import( "@/pages/Forget");
const Faq = () => import( "@/pages/Faq");
const Content = () => import( "@/pages/Content");
const Ranking = () => import(  "@/pages/Ranking");
const Activity = () => import(  "@/pages/Activity");
const About = () => import(  "@/pages/About");
const Contact = () => import(  "@/pages/Contact");
const NewsList = () => import(  "@/pages/NewsList");
const NewsDetail = () => import(  "@/pages/NewsDetail");
const Careers = () => import(  "@/pages/Careers");
const Profile = () => import(  "@/pages/setting/Profile");
const Payment = () => import(  "@/pages/setting/Payment");
const Notifications = () => import(  "@/pages/setting/Notifications");
const Seller = () => import(  "@/pages/setting/Seller");
const Creator = () => import(  "@/pages/setting/Creator");
const ActivityList = () => import(  "@/pages/dashboard/ActivityList");
const OrderList = () => import(  "@/pages/dashboard/OrderList");
const TransactionList = () => import(  "@/pages/dashboard/TransactionList");
const OfferList = () => import(  "@/pages/dashboard/OfferList");
const DepositNft = () => import(  "@/pages/dashboard/DepositNft");
const Redeem = () => import(  "@/pages/dashboard/Redeem");
const CreateSingle = () => import(  "@/pages/CreateSingle");
const Term = () => import(  "@/pages/Term");
const Policy = () => import(  "@/pages/Policy");
const Collection = () => import(  "@/pages/Collection");
const Author = () => import(  "@/pages/Author");
const NftList = () => import( "@/pages/dashboard/NftList");
const OrderStatus = () => import( "@/pages/OrderStatus");
const CollectionEdit = () => import( "@/pages/dashboard/CollectionUpdate");
const routes = [
    //首页
    {path: "/", name: 'Home', component: Home},
    //登录
    {path: "/login", component: Login, meta: {requiresNoLogin: true}},
    {path: "/reg", component: Reg, meta: {requiresNoLogin: true}},
    {path: "/reg/verify", component: RegVerify, meta: {requiresNoLogin: true}},
    {path: "/reset", component: Forget, meta: {requiresNoLogin: true}},
    {path: "/reset/verify", component: ForgetVerify, meta: {requiresNoLogin: true}},
    {path: "/reset/password", component: ForgetReset, meta: {requiresNoLogin: true}},
    //其他
    {path: "/explore", component: Explore},
    {path: "/token/:contractAddress/:tokenId", component: Detail},
    {path: "/ranking", component: Ranking},
    {path: "/activity", component: Activity},
    {path: "/about", component: About},
    {path: "/contact", component: Contact},
    {path: "/blog", component: NewsList},
    {path: "/blog/detail/:Id", component: NewsDetail},
    {path: "/careers", component: Careers},
    {path: "/create", component: CreateSingle},
    {path: "/term", component: Term},
    {path: "/faq", component: Faq},
    {path: "/policy", component: Policy},
    {path: "/:collection", name: 'Collection', component: Collection},
    {path: "/user/:address", name: 'Author', component: Author},
    {path: "/order/status", name: 'OrderStatus', component: OrderStatus},
    //个人设置
    {path: "/setting/profile", component: Profile},
    {path: "/setting/payment", component: Payment},
    {path: "/setting/notifications", component: Notifications},
    {path: "/setting/seller", component: Seller},
    {path: "/setting/creator", component: Creator},
    //个人中心
    {path: "/account/assets", component: NftList},
    {path: "/account/offers", component: OfferList},
    {path: "/account/transactions", component: TransactionList},
    {path: "/account/orders", component: OrderList},
    {path: "/account/activity", component: ActivityList},
    {path: "/account/deposit", component: DepositNft},
    {path: "/account/collections", component: Author},
    {path: "/account/redeem", component: Redeem},
    {path: "/account/collection/edit", component: CollectionEdit},
    //单页内容
    {path: "/content/:symbol", component: Content}
];
const router = createRouter({
    history: createWebHistory(), routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {left: 0, top: 0}
        }
    }
});
router.beforeEach((to, from) => {
    if (sessionStorage.getItem("token") != null) {
        if (to.meta.hasOwnProperty("requiresNoLogin")) {
            return from
        }
    }
})
export default router;