import axios from 'axios'
import qs from 'qs'
import router from '../router'

axios.defaults.timeout = 60000;                        //响应时间
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';        //配置请求头
axios.defaults.baseURL = '/api';   //配置接口地址

axios.interceptors.request.use((config) => {
    if (config.method === 'post') {
        config.data = qs.stringify(config.data)
    }
    let token = sessionStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((res) => {
    if (parseInt(res.data.code) === 403) {
        sessionStorage.removeItem('token')
        let urlLogin = "/login";
        router.replace(urlLogin)
        return Promise.reject(res)
    } else {
        return Promise.resolve(res);
    }
}, (error) => {
    return Promise.reject(error);
});

export function Post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function Get(url, param) {
    return new Promise((resolve, reject) => {
        axios.get(url, {params: param})
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function Upload(url, data) {
    let instance = axios.create({baseURL: '/api'});
    instance.defaults.headers.post['Content-Type'] = 'multipart/form-data;charse=UTF-8';
    return new Promise((resolve, reject) => {
        instance.post(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export default {
    Post,
    Get,
    Upload,
}


