export function formatDate(time, format = 'YY-MM-DD hh:mm:ss') {
    let date = new Date(parseInt(time) * 1000);
    let year = date.getFullYear(),
        month = date.getMonth() + 1,//月份是从0开始的
        day = date.getDate(),
        hour = date.getHours(),
        min = date.getMinutes(),
        sec = date.getSeconds();
    let preArr = Array.apply(null, Array(10)).map(function (elem, index) {
        return '0' + index;
    });
    return format.replace(/YY/g, year)
        .replace(/MM/g, preArr[month] || month)
        .replace(/DD/g, preArr[day] || day)
        .replace(/hh/g, preArr[hour] || hour)
        .replace(/mm/g, preArr[min] || min)
        .replace(/ss/g, preArr[sec] || sec);
}

export function formatFloat(num) {
    let rightNumber;
    try {
        rightNumber = num.toString().split(".")[1].length
    } catch (e) {
        rightNumber = 0
    }
    if (rightNumber !== 0) {
        if (rightNumber === 1) {
            return parseFloat(num).toFixed(1)
        } else {
            return parseFloat(num).toFixed(2)
        }
    } else {
        return parseInt(num)
    }
}


export function slideUp(target, duration = 500) {
    target.style.transitionProperty = 'height, margin, padding'
    target.style.transitionDuration = duration + 'ms'
    target.style.boxSizing = 'border-box'
    target.style.height = target.offsetHeight + 'px'
    // eslint-disable-next-line no-unused-expressions
    target.offsetHeight
    target.style.overflow = 'hidden'
    target.style.height = 0
    target.style.paddingTop = 0
    target.style.paddingBottom = 0
    target.style.marginTop = 0
    target.style.marginBottom = 0
    window.setTimeout(() => {
        target.style.display = 'none'
        target.style.removeProperty('height')
        target.style.removeProperty('padding-top')
        target.style.removeProperty('padding-bottom')
        target.style.removeProperty('margin-top')
        target.style.removeProperty('margin-bottom')
        target.style.removeProperty('overflow')
        target.style.removeProperty('transition-duration')
        target.style.removeProperty('transition-property')
    }, duration)
}

export function slideDown(target, duration = 500) {
    target.style.removeProperty('display')
    let display = window.getComputedStyle(target).display
    if (display === 'none') display = 'block'
    target.style.display = display
    let height = target.offsetHeight
    target.style.overflow = 'hidden'
    target.style.height = 0
    target.style.paddingTop = 0
    target.style.paddingBottom = 0
    target.style.marginTop = 0
    target.style.marginBottom = 0
    // eslint-disable-next-line no-unused-expressions
    target.offsetHeight
    target.style.boxSizing = 'border-box'
    target.style.transitionProperty = 'height, margin, padding'
    target.style.transitionDuration = duration + 'ms'
    target.style.height = height + 'px'
    target.style.removeProperty('padding-top')
    target.style.removeProperty('padding-bottom')
    target.style.removeProperty('margin-top')
    target.style.removeProperty('margin-bottom')
    window.setTimeout(() => {
        target.style.removeProperty('height')
        target.style.removeProperty('overflow')
        target.style.removeProperty('transition-duration')
        target.style.removeProperty('transition-property')
    }, duration)
}

export function slideToggle(target, duration = 500) {
    if (window.getComputedStyle(target).display === 'none') {
        return slideDown(target, duration)
    } else {
        return slideUp(target, duration)
    }
}

export function toggleDropdown(parent, subMenu, _active) {
    if (!parent.classList.contains(_active)) {
        parent.classList.add(_active)
        // eslint-disable-next-line no-undef
        slideDown(subMenu)
    } else {
        parent.classList.remove(_active)
        // eslint-disable-next-line no-undef
        slideUp(subMenu)
    }
}

export function closeDropdownSiblings(siblings, menu, _sub, _active) {
    Array.from(siblings).forEach(item => {
        if (item.classList.contains(_active) && !menu.classList.contains(_active)) {
            item.classList.remove(_active)
            Array.from(item.children).forEach(subItem => {
                if (subItem.classList.contains(_sub)) {
                    // eslint-disable-next-line no-undef
                    slideUp(subItem)
                }
            })
        }
    })
}

export function menuDropdown(navbarAttr) {
    const menu_toggle = document.querySelectorAll('.' + navbarAttr._menu_toggle)
    menu_toggle.forEach(item => {
        item.addEventListener('click', function (e) {
            e.preventDefault()
            let itemParent = item.parentElement
            let itemSibling = item.nextElementSibling
            let itemParentSiblings = item.parentElement.parentElement.children
            closeDropdownSiblings(itemParentSiblings, itemParent, navbarAttr._menu_sub, navbarAttr._menu_active)
            toggleDropdown(itemParent, itemSibling, navbarAttr._menu_active)
        })
    })
}

export function mobileNavInit(navbarAttr) {
    const navbar = document.querySelector('.' + navbarAttr._navbar)
    if (window.innerWidth <= navbarAttr._navbar_break) {
        navbar.classList.add(navbarAttr._navbar_mobile)
    }
}

export function mobileNavResize(navbarAttr) {
    const navbar = document.querySelector('.' + navbarAttr._navbar)
    const navbar_toggle = document.querySelector('.' + navbarAttr._navbar_toggle)
    window.addEventListener('resize', function () {
        if (window.innerWidth <= navbarAttr._navbar_break) {
            navbar.classList.add(navbarAttr._navbar_mobile)
        } else {
            navbar.classList.remove(navbarAttr._navbar_mobile, navbarAttr._navbar_active)
            navbar_toggle.classList.remove(navbarAttr._navbar_active)
        }
    })
}

export function mobileNavToggle(navbarAttr) {
    const navbar = document.querySelector('.' + navbarAttr._navbar)
    const navbar_toggle = document.querySelector('.' + navbarAttr._navbar_toggle)
    if (navbar_toggle) {
        navbar_toggle.addEventListener('click', function () {
            navbar_toggle.classList.toggle(navbarAttr._navbar_active)
            navbar.classList.toggle(navbarAttr._navbar_active)
        })
    }
}

export function navOutSideClick(navbarAttr) {
    const navbar = document.querySelector('.' + navbarAttr._navbar)
    const navbar_toggle = document.querySelector('.' + navbarAttr._navbar_toggle)
    document.addEventListener('click', function (event) {
        if (event.target !== navbar && event.target !== navbar_toggle &&
            // eslint-disable-next-line camelcase
            event.target.closest('.' + navbarAttr._navbar) == null && event.target.closest('.' + navbarAttr._navbar_toggle) == null) {
            // eslint-disable-next-line camelcase
            if (navbar_toggle) {
                navbar_toggle.classList.remove(navbarAttr._navbar_active)
            }
            navbar.classList.remove(navbarAttr._navbar_active)
        }
    })
}

export function stickyMenu(selector) {
    let elem = document.querySelectorAll(selector)
    if (elem.length > 0) {
        elem.forEach(item => {
            let _item_offset = item.offsetTop
            window.addEventListener('scroll', function () {
                if (window.scrollY > _item_offset) {
                    item.classList.add("has-fixed")
                } else {
                    item.classList.remove("has-fixed")
                }
            })
        })
    }
}

export function themeSwitcher(selector) {
    let themeToggler = document.querySelectorAll(selector)
    if (themeToggler.length > 0) {
        themeToggler.forEach(item => {
            item.addEventListener('click', function (e) {
                e.preventDefault()
                document.body.classList.toggle('dark-mode')
                if (document.body.classList.contains('dark-mode')) {
                    localStorage.setItem('website_theme', 'dark-mode')
                } else {
                    localStorage.setItem('website_theme', 'default')
                }
            })
        })
    }

    function retrieveTheme() {
        var theme = localStorage.getItem('website_theme')
        if (theme != null) {
            document.body.classList.remove('default', 'dark-mode')
            document.body.classList.add(theme)
        }
    }

    retrieveTheme()

    if (window) {
        window.addEventListener('storage', function () {
            retrieveTheme()
        }, false)
    }
}

export function showHidePassword(selector) {
    let elem = document.querySelectorAll(selector);
    if (elem.length > 0) {
        elem.forEach(item => {
            item.addEventListener("click", function (e) {
                e.preventDefault();
                let target = document.getElementById(item.getAttribute("href"));
                if (target.type == "password") {
                    target.type = "text";
                    item.classList.add("is-shown");
                } else {
                    target.type = "password";
                    item.classList.remove("is-shown");
                }
            });

        });
    }
}

export function userSidebarMenu() {
    let sidebarMenuOpen = document.querySelector(".menu-toggler-user-open");
    let userSidebar = document.querySelector(".sidebar-user-mobile");
    if (sidebarMenuOpen) {
        sidebarMenuOpen.addEventListener("click", function (e) {
            e.preventDefault();
            userSidebar.classList.add('active');
        });
    }
    if (userSidebar) {
        userSidebar.addEventListener('click', function () {
            this.classList.remove('active');
        })
    }
}

export function uploadImage(selector) {
    let elem = document.querySelectorAll(selector)
    if (elem.length > 0) {
        elem.forEach(item => {
            item.addEventListener('change', function () {
                if (item.files && item.files[0]) {
                    let img = document.getElementById(item.dataset.target)
                    img.onload = function () {
                        URL.revokeObjectURL(img.src)
                    }
                    img.src = URL.createObjectURL(item.files[0])

                    let allowedExtensions = ['jpg', 'JPEG', 'JPG', 'png']
                    let fileExtension = this.value.split('.').pop()
                    let lastDot = this.value.lastIndexOf('.')
                    let ext = this.value.substring(lastDot + 1)
                    let extTxt = img.value = ext
                    if (!allowedExtensions.includes(fileExtension)) {
                        alert(extTxt + ' file type not allowed, Please upload jpg, JPG, JPEG, or png file')
                        img.src = ' '
                    }
                }
            })
        })
    }
}

export function checkboxAllToggle(selector, selectorInputText) {
    let checkAllBtn = document.querySelectorAll(selector)
    let checkAllInput = document.querySelectorAll(selectorInputText)

    if (checkAllBtn.length > 0) {
        checkAllBtn.forEach(item => {
            item.addEventListener('click', function (e) {
                if (e.target.value === 'Check All') {
                    checkAllInput.forEach(function (checkbox) {
                        checkbox.checked = true
                    })
                    e.target.value = 'Uncheck All'
                } else {
                    checkAllInput.forEach(function (checkbox) {
                        checkbox.checked = false
                    })
                    e.target.value = 'Check All'
                }
            })
        })
    }
}

export function getUserInfo() {
    let userJson = sessionStorage.getItem("user")
    if (userJson != null) {
        return JSON.parse(userJson)
    }
}

export function nftPath(contractAddress, tokenId) {
    return "/token/" + contractAddress + "/" + tokenId
}

export function collectionPath(contractAddress) {
    return "/collection/" + contractAddress
}

export function authorPath(address) {
    return "/user/" + address
}

export const fileToBuffer = (file) => {
    return new Promise((r) => {
        const reader = new FileReader();
        reader.onload = () => {
            r(reader.result);
        };
        reader.readAsArrayBuffer(file);
    });
};

export function shortStr(hash) {
    if (hash === "" || hash === undefined) {
        return ""
    }
    let number = parseInt(hash.length)
    if (number > 13) {
        return hash.substr(0, 6) + "..." + hash.substr(number - 6, number)
    } else {
        return hash;
    }
}

export default {
    formatDate,
    formatFloat,
    slideUp,
    slideDown,
    slideToggle,
    toggleDropdown,
    closeDropdownSiblings,
    menuDropdown,
    mobileNavInit,
    mobileNavResize,
    mobileNavToggle,
    navOutSideClick,
    stickyMenu,
    themeSwitcher,
    showHidePassword,
    userSidebarMenu,
    uploadImage,
    checkboxAllToggle,
    getUserInfo,
    nftPath,
    collectionPath,
    authorPath,
    fileToBuffer,
    shortStr
}
