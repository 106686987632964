module.exports = {
    header: {
        searchText: "Search NFTs/Collections",
        loginText: "Login",
        regText: "Sign Up",
        nav: {
            home:"Home",
            explore: "Explore",
            stats: "Stats",
            company: "Company",
            activity: "Activity",
            ranking: "Ranking",
            about: "About Us",
            news: "News",
            contact: "Contact Us",
            careers: "Careers"
        }
    },
    footer: {
        content: "Region’s first digital marketplace for" + " entertainment related crypto collectibles" + " and non-fungible tokens NFTs.",
        term: "Terms",
        policy: "Privacy Policy",
        copyRight: "Copyright © 2022 Metaviva",
        nav: {
            marketPlace: "Marketplace",
            account: "My Account",
            company: "Company",
            allNft: "All NFTs",
            artNft: "Art",
            collectiblesNft: "Collectibles",
            sportsNft: "Sports",
            utilityNft: "Utility",
            about: "About Us",
            news: "News",
            contact: "Contact Us",
            careers: "Careers",
            profile: "Profile Settings",
            collections: "My Collections",
            settings: "Seller Settings",
        }
    },
    slider: {
        title: "Discover rare artworks by world class artists",
        subTitle: "The Singapore's first and largest digital marketplace for crypto collectibles and non-fungible tokens (NFTs)."
    },
    page: {
        login: {
            title: "Login In To Metaviva",
            subTitle: "Please login to continue",
            userLabel: "Email/Mobile",
            passLabel: "Password",
            rememberText: "Remember me",
            forgetText: "Forget Password?",
            noAccountText: "Don't have an account?",
            passToggle: "Toggle show/hide password",
            signUpText: "Sign Up",
            buttonText: "Login",
            regSuccessText: "Sign Up Successful",
            resetSuccessText: "Reset Password Successful"
        },
        reg: {
            title: "Sign Up To Metaviva",
            subTitle: "Fill up the below fields to create an account",
            mobileTab: "Mobile Phone",
            emailTab: "Email",
            mobileLabel: "Mobile Phone",
            emailLabel: "Email Address",
            nicknameLabel: "Full Name",
            passwordLabel: "Password",
            passwordToggle: "Toggle show/hide password",
            regButton: "Sign Up Now",
            hasAccountText: "Have an Metaviva account?",
            loginLink: "Login"
        },
        regVerify: {
            title: "Get Verification Code",
            subTitle: "Verification code has been sent to:",
            verifyCodeLabel: "Verification Code",
            verifyCodePlaceholder: "Verification Code",
            submitButton: "Confirm",
            sendButton: "Resent Verification Code",
            sendWaitText: 'Resend Code in {seconds} seconds',
        },
        forget: {
            title: "Reset Password",
            subTitle: "",
            mobileTab: "Mobile Phone",
            emailTab: "Email",
            mobileLabel: "Mobile Phone",
            emailLabel: "Email Address",
            submitButton: "Confirm",
        },
        reset: {
            title: "Reset Your Password",
            subTitle: "",
            passwordLabel: "New Password",
            repeatLabel: "Password Again",
            submitButton: "Reset",
        },
    },
}